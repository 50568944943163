
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        













































.form-input,
[class*='form-input--'] {
  @extend %box-shadow;

  position: relative;
  padding: $spacing * 1.25 $spacing;
  border-radius: 4px;
}

.form-input__label {
  @extend %fw-bold;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $spacing * 1.25 $spacing;
  font-family: $ff-alt;
  transition: top $ease 0.1s, font-size $ease 0.1s;

  sup {
    top: 0;
    font-size: 100%;
  }
}

.form-input__field {
  @extend %fw-bold;

  width: 100%;
  border: 0;
  background: transparent;

  &:focus,
  &.is-filled {
    + .form-input__label {
      top: -$spacing;
      color: $c-gray;
      font-size: 1rem;
      pointer-events: none;
      // transform: translateY(-20%);
    }
  }
}
